import React from "react";

import Dialog from '@material-ui/core/Dialog';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import config from "../config";

export default function AdListFirstTimePopup(props) {

  var popupContent = props.popupContent;
  popupContent = popupContent.replace(/\n/g, "<br />")

  localStorage.setItem('displayCreditImage', 'false');
  var displayCreditImage = props.displayCreditImage;
  if (props.fsCreditImage === undefined || props.fsCreditImage === '') {
    displayCreditImage = false;
  }

  var foEntryPopupDetails = JSON.parse(localStorage.getItem('foGlobalSetting'))?.foEntryPopupDetails;
  SwiperCore.use([Autoplay]);
  return (
    <Dialog open={props.openDialog}
      classes={{ root: 'fullWidthPopup' }}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="mobyPopup adFirstTimePopupDesign">
        <div className="popupBody">
          <span className="material-icons-outlined adFirstTimeClose-icon" onClick={() => props.popupRedirection(0)} >close</span>
          <div className="offer-body">
            {/* {config.foUserCurrencyResult()?.fsCurrencyCode === "INR" ? (
              <div className="offer-group">
                <div className="offer-item" style={{ 'backgroundColor': '#cfefca', 'marginRight': '7px' }} onClick={() => props.popupRedirection(-1)}>
                  <img src={config.IMAGE_URL + 'images/pwa-images/popup/1.png'} />
                  <span style={{ 'color': '#57ca55' }}>{foEntryPopupDetails.fiOfflineEateries}</span>
                  <span>Nearby Eatery</span>
                  <span>Instant Discount</span>
                  <span className="material-icons-outlined" style={{ 'backgroundColor': '#57ca55' }}>location_on</span>
                </div>
                <div className="offer-item" style={{ 'backgroundColor': '#ffcfd1', 'marginLeft': '7px' }} onClick={() => props.popupRedirection(-1)}>
                  <img src={config.IMAGE_URL + 'images/pwa-images/popup/2.png'} />
                  <span style={{ 'color': '#ff6367' }}>{foEntryPopupDetails.fiOfflineStores}</span>
                  <span>Nearby Stores</span>
                  <span>Instant Cashback</span>
                  <span className="material-icons-outlined" style={{ 'backgroundColor': '#ff6367' }}>location_on</span>
                </div>
              </div>) : null} */}
            <div className="offer-group">
              <div
                className="offer-item"
                style={{
                  backgroundColor: '#cfefca',
                  marginRight: '7px',
                  pointerEvents: config.foUserCurrencyResult()?.fsCurrencyCode === "INR" ? 'auto' : 'none',
                  opacity: config.foUserCurrencyResult()?.fsCurrencyCode === "INR" ? 1 : 0.5
                }}
                onClick={() => config.foUserCurrencyResult()?.fsCurrencyCode === "INR" && props.popupRedirection(-1)}
              >
                <img src={config.IMAGE_URL + 'images/pwa-images/popup/1.png'} />
                <span style={{ color: '#57ca55' }}>{foEntryPopupDetails.fiOfflineEateries}</span>
                <span>Nearby Eatery</span>
                <span>{config.foUserCurrencyResult()?.fsCurrencyCode === "INR" ? "Instant Discount" : "Coming Soon"}</span>
                <span className="material-icons-outlined" style={{ backgroundColor: '#57ca55' }}>location_on</span>
              </div>

              <div
                className="offer-item"
                style={{
                  backgroundColor: '#ffcfd1',
                  marginLeft: '7px',
                  pointerEvents: config.foUserCurrencyResult()?.fsCurrencyCode === "INR" ? 'auto' : 'none',
                  opacity: config.foUserCurrencyResult()?.fsCurrencyCode === "INR" ? 1 : 0.5
                }}
                onClick={() => config.foUserCurrencyResult()?.fsCurrencyCode === "INR" && props.popupRedirection(-1)}
              >
                <img src={config.IMAGE_URL + 'images/pwa-images/popup/2.png'} />
                <span style={{ color: '#ff6367' }}>{foEntryPopupDetails.fiOfflineStores}</span>
                <span>Nearby Stores</span>
                <span>{config.foUserCurrencyResult()?.fsCurrencyCode === "INR" ? "Instant Cashback" : "Coming Soon"}</span>
                <span className="material-icons-outlined" style={{ backgroundColor: '#ff6367' }}>location_on</span>
              </div>
            </div>
          </div>
          <div className="offer-body">
            <div className="offer-group">
              <div className="offer-item" style={{ 'backgroundColor': '#ffedb8', 'marginRight': '7px' }} onClick={() => props.popupRedirection(68)}>
                <img src={config.IMAGE_URL + 'images/pwa-images/popup/3.png'} />
                <span style={{ 'color': '#ffc235' }}>{foEntryPopupDetails.fiOnlineStores}</span>
                <span>Online Offers</span>
                <span>Instant Cashback</span>
                <span className="material-icons-outlined" style={{ 'backgroundColor': '#ffc235' }}>wifi</span>
              </div>
              <div className="offer-item" style={{ 'backgroundColor': '#ffe1fd', 'marginLeft': '7px' }} onClick={() => props.popupRedirection(70)}>
                <img src={config.IMAGE_URL + 'images/pwa-images/popup/4.png'} />
                <span style={{ 'color': '#9f0795' }}>{foEntryPopupDetails.fiOnlineFood}</span>
                <span>Free Gifts</span>
                <span>Instant Discount</span>
                <span className="material-icons-outlined" style={{ 'backgroundColor': '#9f0795' }}>wifi</span>
              </div>
            </div>
          </div>
          <div className="offer-body">
            <div className="popupBanner">
              <Swiper slidesPerView={1} spaceBetween={20}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false
                }} >
                {displayCreditImage === true ?
                  <SwiperSlide key={0}>
                    <img src={props.fsCreditImage} onClick={() => props.popupRedirection(0)} />
                  </SwiperSlide> : ''}
                {props.fsAdImage.map((image, i) =>
                  <SwiperSlide key={i}>
                    <img src={image.fsImage} onClick={() => props.handleClose(i)} />
                  </SwiperSlide>
                )}
              </Swiper>
            </div>
          </div>
        </div>

      </div>
    </Dialog>
  );
}