import React from "react";
import config from './../config';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
// import { Link } from 'react-router-dom';

export default function TimeLineList(props) {
    return (
        <div className="timelineList">

            {props.timeLine.map((singleTimeline, i) => {
                var cardColorClass = singleTimeline.fsPinColor;
                if (singleTimeline.fsPinColor === "red-voucher") {
                    cardColorClass = "red";
                }
                var cardColor = 'singleCouponTimeLine ' + cardColorClass + 'Card';

                if (props.fiHighlightActivity === singleTimeline.fiActivityId) {
                    cardColor = cardColor + ' activeCardView';
                }

                var expiredCoupon = '';
                if (singleTimeline.fsRemainDay !== "" && singleTimeline.fbIsCouponUsed !== true) {
                    expiredCoupon = <div className="expiredCoupon">
                        {singleTimeline.fsRemainDay}
                    </div>;
                }

                var isBlinkUploadBillButton = "";
                if (singleTimeline.fbIsBlinkShopOnline) {
                    isBlinkUploadBillButton = 'blink_me';
                }
                var giftCardButton = <div className="couponBtn">
                    <Button className={isBlinkUploadBillButton} variant="contained" onClick={() => props.goToUploadBill(i)} color="primary">
                        {singleTimeline.fsPinColor == "red-voucher" ? 'View Voucher' : 'View & Shop'}
                    </Button>
                </div>;
                if (singleTimeline.fsMarkIsUsedFlow === 'offline-hybrid-green') {
                    giftCardButton = <div className="couponBtn"><Button onClick={() => props.goToUploadBill(i)} variant="contained" color="primary">
                        View & Pay
                    </Button></div>;
                }
                if (singleTimeline.fsMarkIsUsedFlow === 'install-register') {
                    giftCardButton = <div className="couponBtn"><Button onClick={() => props.goToUploadBill(i)} variant="contained" color="primary">
                        View Task
                    </Button></div>;
                }
                if (singleTimeline.fbIsCouponUsed === true) {
                    if (singleTimeline.fsPinColor == "red-voucher") {
                        giftCardButton = <div className="couponBtn"><Button onClick={() => props.goToUploadBill(i)} variant="contained" color="primary">
                            View Voucher
                        </Button></div>;
                    } else if (singleTimeline.fsMarkIsUsedFlow === 'offline-hybrid-green') {
                        giftCardButton = <div className="couponBtn alreadyUsed"><Button onClick={() => props.handleRedPopupDisplay('giftCardUsed')} variant="contained" color="primary">
                            View & Pay
                        </Button></div>;
                    } else {
                        giftCardButton = <div className="couponBtn alreadyUsed"><Button onClick={() => props.handleRedPopupDisplay('giftCardUsed')} variant="contained" color="primary">
                            View & Shop
                        </Button></div>;
                    }
                }

                if (singleTimeline.fbIsCouponExpired === true) {
                    giftCardButton = <div className="couponBtn">
                        <Button onClick={() => props.handleRedPopupDisplay('giftCardExpired')} variant="contained" color="primary">
                            {singleTimeline.fsPinColor == "red-voucher" ? 'View Voucher' : 'View & Shop'}
                        </Button>
                    </div>;
                }

                var btnMarkAsUsedVoucher = <div className="couponBtn voucher-btn">
                    <Button onClick={() => { props.updateMarkAsRead(i) }} variant="contained" color="primary">Mark as Used</Button>
                </div>
                if (singleTimeline.fbIsCouponUsed === true) {
                    btnMarkAsUsedVoucher = <div className="couponBtn voucher-btn alreadyUsed">
                        <Button variant="contained" color="primary">Mark as Used</Button>
                    </div>
                }

                /* Register Bill Button Logic */
                var uploadBillButton = <Button variant="contained">Register Bill</Button>;
                if (singleTimeline.fbIsCouponUsed === true) {
                    uploadBillButton = <Button onClick={() => props.handleRedPopupDisplay('billUploaded')} variant="contained">Register Bill</Button>;
                }

                if (singleTimeline.fbIsBillUploadEnable === false || singleTimeline.fsPinColor === "red-voucher") {
                    uploadBillButton = "";
                }

                var offDiscountPrice = "";
                if (singleTimeline.fsFlatCashBack === "" && singleTimeline.fsDiscountUpTo === "") {
                    if (singleTimeline.fiSecondReward > 0) {
                        offDiscountPrice = <div className="quizRewardDesign fontBold smallFont" style={{ 'marginTop': '4px' }}>
                            <span>{config.getCashbackString(singleTimeline.fbIsMyCash)} Amount : </span><span className="colorBlue">{singleTimeline.fiSecondReward > 0 ? <span> {config.foUserCurrencyResult()?.fsCurrencyIcon || "Rs."} {(singleTimeline.fiSecondReward * (config.foUserCurrencyResult()?.fiExchangeRate || 1)).toFixed(2)}</span> : ''}</span>
                        </div>;
                    }
                } else {
                    if (singleTimeline.fsDiscountUpTo !== "") {
                        offDiscountPrice = 'Upto <span>' + singleTimeline.fsDiscountUpTo + '</span> Discount';
                    }
                    if (singleTimeline.fsMarkIsUsedFlow === 'offline-hybrid-green' || singleTimeline.fsMarkIsUsedFlow === 'offline-hybrid-green') {
                        offDiscountPrice = 'Exact <span>' + singleTimeline.fsDiscountUpTo + '</span> Off';
                    }
                    if (singleTimeline.fsFlatCashBack !== "") {
                        if (offDiscountPrice != "") {
                            offDiscountPrice = offDiscountPrice + ' + ';
                        }
                        if (singleTimeline.fsMarkIsUsedFlow === 'offline-hybrid-green' || singleTimeline.fsMarkIsUsedFlow === 'offline-hybrid-green') {
                            offDiscountPrice = offDiscountPrice + ' <span>' + singleTimeline.fsFlatCashBack + '</span> Extra,Use <b style="margin-left:2px">' + config.getGlobalCashbackString() + '</b>';
                        } else {
                            offDiscountPrice = offDiscountPrice + ' <span>' + singleTimeline.fsFlatCashBack + '</span> ' + config.getCashbackString(singleTimeline.fbIsMyCash);
                        }
                    }
                    offDiscountPrice = <div className="offerTextOnListing" dangerouslySetInnerHTML={{ __html: offDiscountPrice }}></div>;
                }

                if (singleTimeline.fsPinColor == "red-voucher") {
                    // Old Text
                    // offDiscountPrice = <div class="offerTextOnListing">Buy using {config.getGlobalCashbackString()} + Extra <span>{singleTimeline.fsFlatCashBack}</span> Instant Discount</div>; 
                    let fiTotalInstantCash = JSON.parse(localStorage.getItem('foGlobalSetting')).fiTotalInstantCash;
                    if (fiTotalInstantCash > 0 && singleTimeline.fiDefVouDenomination > 0) {
                        offDiscountPrice = <div class="offerTextOnListing">Exact <span class="zoomIt">{singleTimeline.fsDiscountUpTo}</span> Off + Extra <span class="zoomIt">{singleTimeline.fsFlatCashBack}</span>, Use <b style={{ 'marginLeft': '5px' }}>{config.getGlobalCashbackString()}</b></div>;
                    } else {
                        offDiscountPrice = <div class="offerTextOnListing">Upto <span class="zoomIt">{singleTimeline.fsDiscountUpTo}</span> Off + Extra <span class="zoomIt">{singleTimeline.fsFlatCashBack}</span>, Use <b style={{ 'marginLeft': '5px' }}>{config.getGlobalCashbackString()}</b></div>;
                    }
                }

                if (singleTimeline.fsUptoText !== "") {
                    let fsUptoText = singleTimeline.fsUptoText;
                    fsUptoText = fsUptoText.replace(/{discountupto}/g, '<span>' + singleTimeline.fsDiscountUpTo + '</span>');
                    fsUptoText = fsUptoText.replace(/{cashbackupto}/g, '<span>' + singleTimeline.fsFlatCashBack + '</span>');
                    fsUptoText = fsUptoText.replace(/{cashbackamt}/g, '<span>' + singleTimeline.fsFlatCashBack + '</span>');
                    fsUptoText = fsUptoText.replace(/{mycash}/g, config.getGlobalCashbackString());
                    offDiscountPrice = <div class="offerTextOnListing" dangerouslySetInnerHTML={{ __html: fsUptoText }}></div>;
                }

                var fsCouponType = '';
                if (singleTimeline.fsPinColor === 'red' && singleTimeline.fsMarkIsUsedFlow === 'offline') {
                    fsCouponType = <span className="offline-coupon">Offline Coupon</span>;
                } else if ((singleTimeline.fsMarkIsUsedFlow === 'offline-hybrid-green' || singleTimeline.fsMarkIsUsedFlow === 'offline-direct-green') && singleTimeline.fsPinColor === 'green') {
                    fsCouponType = <span className="offline-coupon">Instant Discount</span>;
                } else if ((singleTimeline.fsMarkIsUsedFlow === 'offline-hybrid-green' || singleTimeline.fsMarkIsUsedFlow === 'offline-direct-green') && singleTimeline.fsPinColor === 'red') {
                    fsCouponType = <span className="offline-coupon">Instant Discount</span>;
                } else if (singleTimeline.fsPinColor === 'red-voucher') {
                    fsCouponType = <span className="offline-coupon">Instant Cashback</span>;
                } else if (singleTimeline.fsMarkIsUsedFlow === 'online' && singleTimeline.fiAdCouponType === 0) {
                    fsCouponType = <span className="offline-coupon">Cashback Coupon</span>;
                } else if (singleTimeline.fsMarkIsUsedFlow === 'online' && singleTimeline.fiAdCouponType === 1) {
                    fsCouponType = <span className="offline-coupon best-deals-amz">AMAZON BEST DEALS</span>;
                } else if (singleTimeline.fsPinColor === 'green' && singleTimeline.fsMarkIsUsedFlow === 'install-register') {
                    fsCouponType = <span className="offline-coupon best-deals-amz">EARN {config.getGlobalCashbackString().toUpperCase()}</span>;
                }

                var maxCashbackString = '';
                if (singleTimeline.fiAdCouponType === 0 && singleTimeline.fsPinColor !== "red-voucher") {
                    if (!(singleTimeline.fsMarkIsUsedFlow === 'offline-hybrid-green' || singleTimeline.fsMarkIsUsedFlow === 'offline-direct-green')) {
                        maxCashbackString = <span className="maxCashback">Max {config.getCashbackString(singleTimeline.fbIsMyCash)} On Used Coupon</span>;
                    }
                }

                return (
                    <div key={i} className={cardColor}>
                        <Card>
                            <div className="timeLineContent" style={{ 'paddingTop': '8px', 'paddingBottom': '4px' }}>
                                <h3>{singleTimeline.fsAdName}</h3>
                                {fsCouponType}
                                <div className="quizRewardDesign smallFont fontMedium quizReward">
                                    {/* Quiz Won  */}
                                    {singleTimeline.fiQuizReward > 0 ? <span className={'colorRed'}>{config.foUserCurrencyResult()?.fsCurrencyIcon || "Rs."} {(singleTimeline.fiQuizReward * (config.foUserCurrencyResult()?.fiExchangeRate || 1)).toFixed(2)}</span> : ''}
                                    <br />
                                    {maxCashbackString}
                                </div>
                                {/* <div className="colorGray smallFont">{singleTimeline.fdQuizEnggagTime}</div> */}

                                <div className="timelineListWithImage">
                                    <div>
                                        <img src={config.getImageUrl(singleTimeline.fsAdLogo)} alt={singleTimeline.fsAdName} />
                                    </div>
                                    <div>
                                        {offDiscountPrice}
                                        <div className="timelineActions">
                                            <div>
                                                {(singleTimeline.fsCouponCode !== "") ?
                                                    giftCardButton
                                                    :
                                                    ''
                                                }
                                                {singleTimeline.fbIsCouponUsed === false ?
                                                    <div className="uploadBillBtn">
                                                        {singleTimeline.fbIsBlinkShopOnline ?
                                                            <div className="disableBillUpload">{uploadBillButton}</div>
                                                            :
                                                            <div className="enableBillUpload" onClick={() => props.openBillUploadPopup(singleTimeline.fiActivityId, singleTimeline.fdQuizEnggagTime, singleTimeline)}>
                                                                {uploadBillButton}</div>
                                                        }
                                                    </div>
                                                    :
                                                    <div className="uploadBillBtn alreadyUsed">
                                                        {uploadBillButton}
                                                    </div>
                                                }
                                                {(singleTimeline.fsPinColor === "red-voucher") ?
                                                    btnMarkAsUsedVoucher
                                                    :
                                                    ''
                                                }
                                            </div>

                                            {expiredCoupon}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                )
            })}

        </div>

    );
}