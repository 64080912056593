import React, { Component } from "react";
import config from '../config';
import { motion } from "framer-motion";
import Card from '@material-ui/core/Card';
import TopBarTitle from '../TopBarTitle';

import MoreCashbackPopup from '../popup/MoreCashbackPopup';

import InternetNotAvailable from '../InternetNotAvailable';
import CircularProgress from '@material-ui/core/CircularProgress';

import AdSense from 'react-adsense';

export default class MyCashHistory extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true,
      apiResponse: '',
      isOpenPopup: false,
      cashbackType: '',
    }

    this.goBack = this.goBack.bind(this);
    this.openPopup = this.openPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }


  componentWillMount() {
    var requestParams = { "fsAction": "getMyCashHistory" };
    config.fetchInformation(requestParams).then((responseJson) => {
      this.setState({
        apiResponse: responseJson,
        isLoading: false,
      });
    });
  }

  goBack() {
    this.props.history.push({
      pathname: '/wallet',
    });
  }

  openPopup(cashbackType) {
    if (cashbackType === 'instant-cash') {
      this.setState({ 'isOpenPopup': true, cashbackType: 'infoPopup' });
    } else if (cashbackType === 'my-cash') {
      this.setState({ 'isOpenPopup': true, cashbackType: 'infoPopup' });
    } else if (cashbackType === 'pending-cash') {
      this.setState({ 'isOpenPopup': true, cashbackType: 'infoPopup' });
    }
  }

  closePopup() {
    this.setState({ 'isOpenPopup': false, cashbackType: '' });
  }

  render() {

    /* No Internet */
    if (!navigator.onLine) {
      return (
        <InternetNotAvailable />
      )
    }

    var foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));
    var fiTotalMyCash = "0";
    if (this.state.apiResponse !== '') {
      fiTotalMyCash = this.state.apiResponse.fiTotalMyCash
    }
    var NoDataFound = config.getProviderSetting().fsNoData;
    var reason = "No History Found.";
    var loader = <div className="apiCallLoader fullScreenLoader"><CircularProgress color="secondary" /></div>;

    return (
      <motion.div initial="initial" animate="in" exit="out" variants={config.pageAnimation} transition={config.pageTransition}>
        <div className="AppDashboard">
          <div className="topAndSideBar">
            <TopBarTitle strTitle={config.getGlobalCashbackString() + " History"} goBack={this.goBack} />
          </div>

          {this.state.isLoading ? loader :
            <div className="AppContent isNormalView isWalletPage isTopBarOnTop">
              {this.state.isOpenPopup ? <MoreCashbackPopup popupButtonText='Close'
                secondLineText=""
                handleClose={this.closePopup}
                cashbackType={this.state.cashbackType}
                openDialog={true} /> : ''}
              <div className="timelineList walletList">
                {parseInt(foGlobalSetting.fiExpirationId) > 0 ?
                  <div className="my-cash-expiration-message">
                    <Card>
                      Your {config.getGlobalCashbackString()} is Expiring, Shop Now to add to your Cashback
                    </Card>
                  </div>
                  : ''}
                {fiTotalMyCash !== "0" && this.state.apiResponse.foMyCashHistory.length > 0 ?
                  <div className="singleCouponTimeLine MyCashTimeLine">
                    <div className="timeLineContent">
                      <div className="items2 mycash-count">
                        <h3 style={{ margin: '0' }}>{config.getGlobalCashbackString() + " Rewards"}</h3>
                        <div className="adsPrice textRight walletTime colorGreen" style={{ position: 'unset', marginTop: '0' }}>{config.foUserCurrencyResult()?.fsCurrencyIcon || "Rs."} {(fiTotalMyCash * (config.foUserCurrencyResult()?.fiExchangeRate || 1)).toFixed(2)}</div>
                      </div>
                      <div className="historyTable">
                        {this.state.apiResponse.foMyCashHistory.map((singleMyCash, i) =>
                          <div className="mycash-card">
                            <div className="mycash-header">
                              <div>
                                <span>{config.replaceMyCash(singleMyCash.fsTitle)}</span>
                                <span>{config.foUserCurrencyResult()?.fsCurrencyIcon || "Rs."}{((parseInt(singleMyCash.fsRemainAmount) + parseInt(singleMyCash.fsUsedAmount)) * (config.foUserCurrencyResult()?.fiExchangeRate || 1)).toFixed(2)}</span>
                              </div>
                            </div>
                            {singleMyCash.foLog.map((singleLog, i) =>
                              <div className="mycash-log">
                                <span>{config.replaceMyCash(singleLog.fsSubject)}</span>
                                <span>{config.foUserCurrencyResult()?.fsCurrencyIcon || "Rs."}{singleLog.fsType === 'debit' ? '-' : ''}{(singleLog.fiAmount * (config.foUserCurrencyResult()?.fiExchangeRate || 1)).toFixed(2)}</span>
                              </div>
                            )}
                            <div className="mycash-footer">
                              <div>
                                <div>
                                  <span>Expiry: </span>
                                  {singleMyCash.fsExpiryDate.indexOf("Exp") >= 0 ?
                                    <span style={{ 'color': 'red' }}>{singleMyCash.fsExpiryDate}</span> :
                                    <span>{singleMyCash.fsExpiryDate}</span>}
                                </div>
                                <div>
                                  <span>Remaining: </span>
                                  <span>{config.foUserCurrencyResult()?.fsCurrencyIcon || "Rs."}{(singleMyCash.fsRemainAmount * (config.foUserCurrencyResult()?.fiExchangeRate || 1)).toFixed(2)}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* <div className="">
                        <AdSense.Google
                            client={config.adsense.clientId}
                            slot={config.adsense.slotId}
                            style={{ display: 'block' }}
                            format="auto"
                            responsive="true"
                        />
                    </div> */}
                  </div>
                  : <div style={{ padding: '15px' }}>
                    <div className="NoDataFound">
                      <img src={NoDataFound} alt="No Data Found" />
                      <div>Oops!</div>
                      <div>
                        {reason}
                      </div>
                    </div>
                  </div>}
              </div>
            </div>
          }
        </div>
      </motion.div>
    )
  }
}