import React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ContinuePopup from './popup/ContinuePopup';

import RedPin from './images/red.png';
import YellowPin from './images/yellow.png';
import GreenPin from './images/green.png';

import config from './config';


const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    overflow: 'hidden',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export default function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  var foGlobalSetting = JSON.parse(localStorage.getItem('foGlobalSetting'));
  var isMapPopupVisible = true;
  if (foGlobalSetting.fiTotalBillVerified !== undefined && parseInt(foGlobalSetting.fiTotalBillVerified) > 0) {
    isMapPopupVisible = false;
  }
  const [isMapPopup, setMapPopupClose] = React.useState(false);

  var fiUnreadMessageCount = 0;
  if (foGlobalSetting.fiUnReadCount !== undefined) {
    fiUnreadMessageCount = foGlobalSetting.fiUnReadCount;
  }

  const handleSetMapPopupClose = () => {
    setMapPopupClose(false);
  }

  const handleSetMapPopupOpen = () => {
    setMapPopupClose(true);
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const goBackTopBar = () => {
    history.push('/');
  };

  const closeSidebarAndChangeMenu = (item) => {
    if (item === 'logout') {
      localStorage.removeItem('isUserLoggedIn');
      localStorage.removeItem('isProfileUpdated');
      localStorage.removeItem('AppLabels');
      localStorage.removeItem('userPhone');
      localStorage.removeItem('hasTermCheck');
      localStorage.removeItem('userProfile');
      // localStorage.removeItem('OpenPWAUserPromo');
      localStorage.removeItem('isShowMarketingAd');
      localStorage.removeItem('fsReferrer');
      // this.props.history.push({
      //     pathname: '/'
      // });
      window.location.reload();
    } else if (item === 'shareMe') {
      if (foGlobalSetting.fsReferralLink !== undefined && foGlobalSetting.fsReferralLink !== "") {
        if (navigator.share) {
          var shareText = 'The New Way To Shop Online…. MOBY Cashbacks FLAT 10% or 15% or 20% Over 400 Brand Offers Upto 50% + "Over & Above" extra MOBY Cashback! Use my referral code ' + foGlobalSetting.fsReferralCode + ' while signing up, Click ';
          navigator.share({
            title: 'Moby Ads',
            text: shareText,
            url: foGlobalSetting.fsReferralLink,
          }).then(() => console.log('Successful share'))
            .catch((error) => console.log('Error sharing', error));
        }
      } else {
        console.log('Not Supported.');
      }
    } else {
      props.changeMenuItem(item);
      setOpen(false);
    }
  };

  var isShowMenuBar = false;
  if (localStorage.getItem('isProfileUpdated') === 'true') {
    isShowMenuBar = true;
  }

  let location = useLocation();

  var menuIcon = "";
  if (isShowMenuBar === true) {
    if (props.isSingleMessage) {
      menuIcon = <span style={{ 'fontSize': '30px' }} className="material-icons-outlined" onClick={props.isMessageGoBack}>
        keyboard_backspace
      </span>;
    } else {
      menuIcon = <span style={{ 'fontSize': '30px' }} className="material-icons-outlined" onClick={goBackTopBar}>
        keyboard_backspace
      </span>;
    }
  }


  var searchIcon = "";
  if (props.isHome === "true" && isShowMenuBar === true) {
    menuIcon = <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={handleDrawerOpen}
      edge="start"
      className={clsx(classes.menuButton, open && classes.hide)}
    >
      <MenuIcon />
    </IconButton>;
    searchIcon = <div className="searchIcon">
      <Link to="/my-timeline">MY COUPONS</Link>
    </div>;
  }

  var blinkLogo = 'topBarSmallLogo';
  if (localStorage.getItem('isMobyTopBarBlink') !== 'false') {
    blinkLogo += ' blink_me';
  }
  var AppLogo = config.getProviderSetting().fsLogo
  return (
    <div className='userNavigation' style={{ 'position': 'fixed', 'bottom': '0' }}>

      {/* Map Popup Handler */}
      {isMapPopup ?
        <ContinuePopup popupTitle="Nearby	Stores" popupButtonText="Continue To Shop"
          popupContent={'\
        <div class="popupPinAlign"><img src="'+ RedPin + '"/>  Nearby	Store	Discount	/	Voucher	</div>\
        <div class="popupPinAlign"><img src="'+ GreenPin + '"/>  Nearby	Restaurant	Discounts</div>\
        <div class="popupPinAlign"><img src="'+ YellowPin + '"/>  Bank	Credit/Debit	Card	Offers</div>\
        <span class="blink_me">(To Shop	OFFLINE	you	need 1 Online	Purchase)</span><br/>'}
          secondLineText=""
          handleClose={handleSetMapPopupClose} openDialog={true} /> : ''
      }

      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed">
          <Toolbar>
            <ul>

              <li className={location.pathname === '/' ? 'active' : ''}>
                <Link to="/">
                  <span className="material-icons-outlined">
                    verified
                  </span>
                  <span>My Offers</span>
                </Link>
              </li>
              {/* {isMapPopupVisible?
                <li className="deactive" onClick={isMapPopupVisible?handleSetMapPopupOpen:''}>
                    <span className="material-icons-outlined" style={{'fontSize':'27px'}}>
                      room
                    </span>
                    <span style={{'display':'block'}}>Near By</span>
                </li>
              : */}
              <li className={location.pathname === '/map' ? 'active' : ''}>
                <Link to="/map">
                  <span className="material-icons-outlined">
                    room
                  </span>
                  <span>Near By</span>
                </Link>
              </li>
              {/* } */}
              <li>
                <div>
                  <a className="searchFormToggleFixed toggleMenu" onClick={handleDrawerOpen}>
                    <span className="material-icons-outlined menu-bottom-toggle" >
                      {config.getProviderReferral() === "M91APP" ? 'menu' : 'wallet'}
                    </span>
                  </a>
                </div>
              </li>
              <li className={location.pathname === '/my-timeline' ? 'active' : ''}>
                <Link to="/my-timeline">
                  <span className="material-icons-outlined">
                    card_giftcard
                  </span>
                  <span>My Coupons</span>
                </Link>
              </li>
              <li className={location.pathname === '/help' ? 'active' : ''}>
                <Link to="/help">
                  <span className="material-icons-outlined">
                    help_outline
                  </span>
                  <span>Help</span>
                </Link>
              </li>
            </ul>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="temporary"
          anchor="left"
          open={open}
          onClick={handleDrawerClose}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className='redesign-sidebar'>
            <IconButton onClick={handleDrawerClose}>
              <span className="menuCloseIcon">
                <span className="material-icons-outlined">
                  close
                </span>
              </span>
            </IconButton>
            <div className='makeStyles-drawerHeader-8'>
              <div className="sidebarProfile">
                <img alt="App Logo" src={AppLogo} />
                <div className="appTitle">{config.appName}</div>
                <div className="poweredBy">Powered By Moby</div>
              </div>
            </div>
            {config.getProviderReferral() !== "M91APP" ? <Divider /> : ""}
            <div className="sidebarLinks" style={{ 'marginTop': '15px' }}>
              <List>
                {/* <ListItem button onClick={() => closeSidebarAndChangeMenu('shareMe')}>
                    <ListItemIcon>
                      <span className="material-icons-outlined">share</span>
                    </ListItemIcon>
                    <ListItemText primary="Invite Friends" />
                  </ListItem> */}

                {/* <Link to="/map">
                  <ListItem button selected={location.pathname === '/map' ? true : false}>
                    <ListItemIcon>
                      <span className="material-icons-outlined">room</span> 
                    </ListItemIcon>
                    <ListItemText primary="Near By" />
                  </ListItem>
                </Link> */}
                {config.getProviderReferral() === 'MIDDAY' ? <a href="https://www.mid-day.com/">
                  <ListItem button>
                    <ListItemIcon>
                      <span className="material-icons-outlined">arrow_back</span>
                    </ListItemIcon>
                    <ListItemText primary="Back to Mid-day" />
                  </ListItem>
                </a> : ''}
                <Link to="/wallet" className="blink_me">
                  <ListItem button selected={location.pathname === '/wallet' ? true : false}>
                    <ListItemIcon>
                      <span className="material-icons-outlined">paid</span>
                    </ListItemIcon>
                    <ListItemText primary="Wallet" />
                  </ListItem>
                </Link>
                <Link to="/my-timeline">
                  <ListItem button selected={location.pathname === '/my-timeline' ? true : false}>
                    <ListItemIcon>
                      <span className="material-icons-outlined">card_giftcard</span>
                    </ListItemIcon>
                    <ListItemText primary="My Coupons" />
                  </ListItem>
                </Link>
                <Link to="/offers">
                  <ListItem button selected={location.pathname === '/offers' ? true : false}>
                    <ListItemIcon>
                      <span className="material-icons-outlined">
                        verified
                      </span>
                    </ListItemIcon>
                    <ListItemText primary="Ongoing Deals" />
                  </ListItem>
                </Link>
                <Link to="/messages">
                  <ListItem button selected={location.pathname === '/messages' ? true : false}>
                    <ListItemIcon>
                      <span className="material-icons-outlined">message</span>
                    </ListItemIcon>
                    <ListItemText primary={
                      <>
                        Messages
                        <span className='message-menu-text'>
                          {fiUnreadMessageCount > 0 ? <span className='unread-count blink_me'>{fiUnreadMessageCount}</span> : ''}
                        </span>
                      </>
                    } />
                  </ListItem>
                </Link>
                <Link to="/help">
                  <ListItem button selected={location.pathname === '/help' ? true : false}>
                    <ListItemIcon>
                      <span className="material-icons-outlined">help_outline</span>
                    </ListItemIcon>
                    <ListItemText primary="Help ?" />
                  </ListItem>
                </Link>
                <Link to="/support">
                  <ListItem button selected={location.pathname === '/support' ? true : false}>
                    <ListItemIcon>
                      <span className="material-icons-outlined">handshake</span>
                    </ListItemIcon>
                    <ListItemText primary="Support" />
                  </ListItem>
                </Link>
                <Link to="/update-profile">
                  <ListItem button selected={location.pathname === '/update-profile' ? true : false}>
                    <ListItemIcon>
                      <span className="material-icons-outlined">person</span>
                    </ListItemIcon>
                    <ListItemText primary="My Profile" />
                  </ListItem>
                </Link>
                <Link to="/terms-and-condition">
                  <ListItem button selected={location.pathname === '/terms-and-condition' ? true : false}>
                    <ListItemIcon>
                      <span className="material-icons-outlined">sd_storage</span>
                    </ListItemIcon>
                    <ListItemText primary="Our Policies" />
                  </ListItem>
                </Link>

                {/* <ListItem button onClick={() => closeSidebarAndChangeMenu('logout')}>
                  <ListItemIcon>
                    <span className="material-icons-outlined">input</span>
                  </ListItemIcon>
                  <ListItemText primary="Log Out" />
                </ListItem> */}
              </List>

            </div>
          </div>

        </Drawer>
      </div>
    </div>
  );
}
